.big-card {
  background-color: #232627;
}

.card-rounded {
  background-color: black;
}

.btn-dashboard {
  width: 20rem;
  height: 18rem;
  background-color: #343839;
}

@media (max-width: 1600px) {
  .btn-dashboard {
    width: 18.5rem;
    height: 16rem;
  }
}

/* @media (max-width: 1500px) {
  .ps.big-card.rounded.m-3.d-flex.flex-grow-1.flex-column.align-items-center.justify-content-center.overflow-hidden {
    margin: 2rem;
  }

  .ps.big-card.rounded.m-3.d-flex.flex-grow-1.flex-column.align-items-center.justify-content-center.overflow-hidden
    header {
    padding: 2rem;
    margin-bottom: 1rem;
  }
} */

/* @media (max-width: 1500px) {
  .d-flex.overflow-auto div.mx-4 {
    margin: 0 4rem;
  }
  const navigate = useNavigate();

  const createImage = () => {
    navigate("/app/create-an-image");
  };} */

.btn-dashboard:hover {
  background-color: #474c4e;
}

.btn-dashboard:active {
  background-color: #2f3234;
}

.img-box {
  height: 100%;
  object-fit: cover;
}

.img-box video {
  border-radius: 0.5rem;
  height: 100%;
  margin: auto;
  object-fit: cover;
}

.img-dashboard-card {
  margin: auto;
  max-height: 100%;
  max-width: 100%;
}

@media only screen and (max-width: 768px) {
  .btn-dashboard {
    width: 15.5rem;
    height: 4.5rem;
    width: 15.5rem;
    height: 4.5rem;
  }
}

@media only screen and (max-width: 768px) {
  .img-dashboard-card {
    margin: auto;
    max-height: 100%;
    max-width: 100%;
    margin-left: 7px;
  }
}
